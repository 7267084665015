<template>
  <AuthModal @hide="userStore.hideAuthPopup">
    <div :class="$style.container">
      <SharedLogoShortLogo size="l"></SharedLogoShortLogo>
      <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
      <SharedHeadingHeading4 v-html="$t('auth.title')" />
      <AuthForm v-if="showAuthForm" />
      <AuthSocials />
      <AuthBottom :class="$style.bottom" />
      <button
        id="auth-form-btn"
        data-test="auth-form-btn"
        :class="$style.hidden"
        @click="showAuthForm = true"
      ></button>
    </div>
  </AuthModal>
</template>

<script lang="ts" setup>
import AuthModal from "./Auth/AuthModal.vue";
import AuthSocials from "./Auth/AuthSocials.vue";
import AuthBottom from "./Auth/AuthBottom.vue";
import { useUserStore } from "../stores/user";
const userStore = useUserStore();
const showAuthForm = ref(isDev());
</script>

<style module>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-4xl);
  min-height: 374px;
  text-align: center;
}
.form {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-md);
  align-items: stretch;
  max-width: 235px;
  width: 100%;
}
.bottom {
  text-align: center;
  position: absolute;
  bottom: 24px;
}
.hidden {
  display: none;
}
</style>
