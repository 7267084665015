<template>
  <p :class="$style.container">
    <i18n-t keypath="auth.links.announcement">
      <span
        :class="$style.link"
        @click="redirectToPolicy"
        >{{ $t("auth.links.policy") }}
      </span>
      <span
        :class="$style.link"
        @click="redirectToAgreement"
        >{{ $t("auth.links.terms") }}
      </span>
    </i18n-t>
  </p>
</template>

<script setup lang="ts">
const userStore = useUserStore();
function redirectToPolicy() {
  userStore.hideAuthPopup();
  navigateTo("/info/privacy-policy");
}
function redirectToAgreement() {
  userStore.hideAuthPopup();
  navigateTo("/info/user-agreement");
}
</script>

<style module lang="scss">
.container {
  font-size: 10px;
  line-height: 100%;
  letter-spacing: -0.05px;
  color: var(--general-transparent-light-35, rgba(255, 255, 255, 0.35));
  max-width: 290px;
  width: 100%;
}
.link {
  cursor: pointer;
  color: var(--optional-transparent-orange-50);
  &:hover {
    color: var(--optional-transparent-orange-75);
  }
}
</style>
