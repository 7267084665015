<template>
  <div :class="$style.container">
    <LinksSection style="margin-right: 15px">
      <template #caption>{{
        t("page_footer.links.sections[0].title")
      }}</template>
      <template #default>
        <NavLink
          to="info/privacy-policy/"
          data-test="footer-link-privacy-policy"
          :class="$style.link"
          >{{ t("page_footer.links.sections[0].privacy_policy") }}</NavLink
        >
        <NavLink
          to="info/user-agreement/"
          data-test="footer-link-user-agreement"
          :class="$style.link"
          >{{ t("page_footer.links.sections[0].terms_of_service") }}</NavLink
        >
        <NavLink
          to="info/contacts/"
          data-test="footer-link-contacts"
          :class="$style.link"
          >{{ t("page_footer.links.sections[0].contacts") }}</NavLink
        >
      </template>
    </LinksSection>
    <LinksSection>
      <template #caption>{{
        t("page_footer.links.sections[1].title")
      }}</template>
      <template #default>
        <a
          :href="coreStore.vk"
          :class="$style.link"
          data-test="footer-link-vk"
        >
          <IconVk /> {{ t("page_footer.links.sections[1].vk") }}
        </a>
        <a
          :href="coreStore.tg"
          :class="$style.link"
          data-test="footer-link-tg"
        >
          <IconTg /> {{ t("page_footer.links.sections[1].tg") }}
        </a>
      </template>
    </LinksSection>
  </div>
</template>

<script setup lang="ts">
import NavLink from "~/components/Shared/NavLink/NavLink.vue";
import LinksSection from "./Links/LinksSection.vue";

const { t } = useI18n();
const coreStore = useCoreStore();
</script>

<style module lang="scss">
.container {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 730px) {
    justify-content: flex-start;
  }
}
.link {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-style: normal;
  line-height: 100%;
  text-decoration: none !important;
  letter-spacing: -0.09px;
  color: var(--button-text-secondary-default) !important;

  &:hover {
    color: var(--button-text-secondary-hover) !important;
  }
  &:active {
    color: var(--button-text-secondary-pressed) !important;
  }
  &:disabled {
    color: var(--button-text-primary-disabled) !important;
  }
  svg {
    width: 18px;
    margin-right: var(--spacing-2lg);
  }
}
</style>
