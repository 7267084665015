<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 134 32"
    fill="none"
    :class="[$style[props.size]]"
  >
    <path
      d="M128.931 0V17.9557C128.931 19.0223 129.606 19.7335 130.637 19.7335H133.197V23.1113H130.673C127.508 23.1113 125.375 20.9424 125.375 17.6712V0H128.931ZM133.197 12.089H128.611V8.88895H133.197V12.089ZM127.864 12.089H123.277V8.88895H127.864V12.089Z"
      fill="currentColor"
    />
    <path
      d="M108.336 23.1113V0H111.892V23.1113H108.336ZM116.514 12.2667H111.678V8.88895H116.407C119.643 8.88895 121.847 11.0578 121.847 14.329V23.1113H118.292V14.0445C118.292 12.9779 117.58 12.2667 116.514 12.2667Z"
      fill="currentColor"
    />
    <path
      d="M102.299 26.8443V8.88867H105.855V26.5599C105.855 29.831 103.686 31.9999 100.414 31.9999H92.3078V25.5288H95.8633V28.6221H100.521C101.588 28.6221 102.299 27.911 102.299 26.8443ZM102.583 19.7332V23.111H98.1033C93.8367 23.111 90.9922 20.2665 90.9922 15.9998C90.9922 11.7331 93.8367 8.88867 98.1033 8.88867H102.583V12.2665H98.2811C96.0411 12.2665 94.5478 13.7598 94.5478 15.9998C94.5478 18.2398 96.0411 19.7332 98.2811 19.7332H102.583Z"
      fill="currentColor"
    />
    <path
      d="M85.4923 23.1118V8.88948H89.0479V23.1118H85.4923ZM85.1367 4.69389C85.1367 3.41389 85.9901 2.56055 87.2701 2.56055C88.5501 2.56055 89.4034 3.41389 89.4034 4.69389C89.4034 5.9739 88.5501 6.82724 87.2701 6.82724C85.9901 6.82724 85.1367 5.9739 85.1367 4.69389Z"
      fill="currentColor"
    />
    <path
      d="M79.2695 23.1113V0H82.8251V23.1113H79.2695Z"
      fill="currentColor"
    />
    <path
      d="M77.3043 16.1776V17.2443H65.9621V14.7554H73.6777C73.3932 13.1198 72.2554 12.2665 70.7265 12.2665H65.9621L65.9265 8.88867H70.9043C74.7443 8.88867 77.3043 11.9109 77.3043 16.1776ZM66.5309 8.88867V17.9554C66.5309 19.0221 67.2065 19.7332 68.2376 19.7332H76.771V23.111H68.4154C65.2154 23.111 63.082 20.9421 63.082 17.671V8.88867H66.5309Z"
      fill="currentColor"
    />
    <path
      d="M48.5022 8.88867H55.5067C58.7779 8.88867 60.9468 11.0576 60.9468 14.3287V23.111H57.3912V14.0443C57.3912 12.9776 56.6801 12.2665 55.6134 12.2665H52.0578V23.111H48.5022V8.88867ZM39.6133 8.88867H52.0578V23.111H48.5022V12.2665H43.1689V23.111H39.6133V8.88867Z"
      fill="currentColor"
    />
    <path
      d="M33.8685 19.7332V23.111H29.3885C25.1218 23.111 22.2773 20.2665 22.2773 15.9998C22.2773 11.7331 25.1218 8.88867 29.3885 8.88867H33.8685V12.2665H29.5663C27.3263 12.2665 25.8329 13.7598 25.8329 15.9998C25.8329 18.2398 27.3263 19.7332 29.5663 19.7332H33.8685ZM33.5841 23.111V8.88867H37.1397V23.111H33.5841Z"
      fill="currentColor"
    />
    <path
      d="M3.73336 19.5557H15.3245C16.3912 19.5557 17.1023 18.8446 17.1023 17.7779V12.4445H9.42228V8.88895H20.8357V17.6357C20.8357 20.9068 18.6668 23.1113 15.3957 23.1113H0V5.44004C0 2.1689 2.1689 0 5.44004 0H19.2001V3.55558H5.51115C4.44447 3.55558 3.73336 4.26669 3.73336 5.33337V19.5557Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    size?: "l" | "s";
  }>(),
  {
    size: "s",
  },
);
</script>

<style lang="scss" module>
.l {
  width: 256px;
  height: 61px;
}
.s {
  width: 134px;
  height: 62px;
}
</style>
