<template>
  <svg
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.57326 11.4916C3.1886 11.4916 0.687702 8.11951 0.583496 2.5083H2.77983C2.85197 6.62678 4.47113 8.3713 5.75366 8.73099V2.5083H7.82183V6.06027C9.08833 5.9074 10.4188 4.28878 10.8677 2.5083H12.9358C12.5911 4.70243 11.1483 6.32105 10.1222 6.98648C11.1483 7.52602 12.7916 8.93781 13.4168 11.4916H11.1403C10.6513 9.78309 9.43301 8.46122 7.82183 8.28137V11.4916H7.57326Z"
      fill="white"
    />
  </svg>
</template>
