<template>
  <svg
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1835_1890)">
      <path
        d="M11.5145 2.86157C10.1438 2.86157 9.02862 3.97627 9.02862 5.34704C9.02862 5.57625 9.0698 5.79426 9.12845 6.00511L8.13238 7.57689C8.00032 7.54645 7.86423 7.5263 7.72366 7.5263C7.2836 7.5263 6.88472 7.6906 6.57091 7.95338L3.60106 6.65021C3.6033 6.61484 3.61136 6.58216 3.61136 6.5468C3.61136 5.55118 2.80063 4.74134 1.80546 4.74134C0.810284 4.74134 0 5.55073 0 6.5468C0 7.54286 0.810284 8.3527 1.80546 8.3527C2.17613 8.3527 2.52083 8.24078 2.80734 8.04784L5.92581 9.41681C5.97148 10.3726 6.75624 11.1386 7.72366 11.1386C8.71928 11.1386 9.52911 10.3283 9.52911 9.33265C9.52911 9.25789 9.51658 9.18626 9.50673 9.11374L11.1792 7.79938C11.2894 7.81505 11.3995 7.8334 11.5141 7.8334C12.8849 7.8334 14 6.71825 14 5.34749C14 3.97582 12.8849 2.86157 11.5145 2.86157ZM0.95891 6.5468C0.95891 6.08032 1.33898 5.70025 1.80546 5.70025C2.27238 5.70025 2.65245 6.07988 2.65245 6.5468C2.65245 7.01372 2.27238 7.39334 1.80546 7.39334C1.33898 7.39334 0.95891 7.01372 0.95891 6.5468ZM7.72366 10.2078C7.24017 10.2078 6.84712 9.81524 6.84712 9.33176C6.84712 8.84872 7.24017 8.45477 7.72366 8.45477C8.2067 8.45477 8.59975 8.84827 8.59975 9.33176C8.59975 9.81524 8.2067 10.2078 7.72366 10.2078ZM11.5145 6.76705C10.7311 6.76705 10.0945 6.13001 10.0945 5.34704C10.0945 4.56406 10.7311 3.92703 11.5145 3.92703C12.298 3.92703 12.9345 4.56406 12.9345 5.34704C12.9345 6.13001 12.298 6.76705 11.5145 6.76705Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1835_1890">
        <rect
          width="14"
          height="14"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
