<template>
  <SecondaryButton
    :class="$style['balance-btn']"
    @click="showTopUpBalance"
  >
    <div :class="$style['balance-descr']">
      <IconRuble />
      <span :class="$style['balance-title']"> {{ balanceFormatted }} </span>
    </div>
    <!--
      <PrimaryButton
        size="xxs"
        :class="$style['plus-btn']"
      >
        <IconPlus />
      </PrimaryButton>
    -->
  </SecondaryButton>
</template>

<script setup lang="ts">
import SecondaryButton from "~/components/Shared/Button/SecondaryButton.vue";
// import PrimaryButton from "~/components/Shared/Button/PrimaryButton.vue";

const props = defineProps<{
  balance: number;
}>();

const { balance } = toRefs(props);

const showTopUpBalance = () => {};

const balanceFormatted = computed(() => {
  return balance.value.toLocaleString("en-US", { maximumFractionDigits: 2 });
});
</script>

<style module lang="scss">
.balance-btn {
  // min-width: 104px;
  min-width: 80px;
  justify-content: space-between;
  gap: var(--spacing-sm);
  // padding: 0 var(--spacing-2xs) 0 var(--spacing-md);
  padding: 0 var(--spacing-md) 0 var(--spacing-md);

  @media screen and (max-width: $mobile) {
    padding: var(--spacing-2xs, 6px) var(--spacing-2lg) var(--spacing-2xs)
      var(--spacing-md);
  }

  .balance-descr {
    display: flex;
    align-items: center;
    gap: var(--spacing-3xs);
    .balance-title {
      font-size: 16px;
      font-weight: 400;
      line-height: 100%;
      letter-spacing: -0.08px;

      @media screen and (max-width: $mobile) {
        font-size: 14px;
        line-height: 130%;
        letter-spacing: -0.14px;
      }
    }
  }

  .plus-btn {
    width: 32px;
    padding: 0;

    @media screen and (max-width: $mobile) {
      display: none;
    }
  }
}
</style>
