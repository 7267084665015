import type { PromoCodeData } from "~/types/promoCodes";
import { getRefresh } from "~/utils/tokens/getRefresh";

export const usePromoCodesStore = defineStore("promoCodesStore", () => {
  const isPromoCodePopupRequired = ref(false);
  const isPromoCodeChecking = ref(false);
  const isPromoCodeActivating = ref(false);

  const currentPromoCodeData = ref<PromoCodeData>();

  const baseURL = isClient()
    ? `${useRuntimeConfig().public.clientApiBase}api/`
    : `${useRuntimeConfig().public.serverApiBase}api/`;

  const userStore = useUserStore();

  const checkPromoCode = async (code: string) => {
    try {
      currentPromoCodeData.value = undefined;
      isPromoCodeChecking.value = true;
      const data = await $fetch<PromoCodeData>(`/promo_codes/show/`, {
        method: "POST",
        body: { code },
        baseURL,
        credentials: "include",
        headers: userStore.getAuthorizationHeader(),
      });
      if (data) {
        currentPromoCodeData.value = data;
      } else {
        currentPromoCodeData.value = undefined;
      }
    } catch (error: any) {
      if (error.statusCode === 401) {
        const refresh = getRefresh() || "";
        await userStore.refreshTokens(refresh);
        await checkPromoCode(code);
      } else {
        throw error;
      }
    } finally {
      isPromoCodeChecking.value = false;
    }
  };

  const activatePromoCode = async (code: string) => {
    try {
      isPromoCodeActivating.value = true;
      const data = await $fetch<PromoCodeData>(`/promo_codes/activate/`, {
        method: "POST",
        body: { code },
        baseURL,
        credentials: "include",
        headers: userStore.getAuthorizationHeader(),
      });
      userStore.getUser();
      return data;
    } catch (error: any) {
      if (error.statusCode === 401) {
        const refresh = getRefresh() || "";
        await userStore.refreshTokens(refresh);
        await activatePromoCode(code);
      } else {
        throw error;
      }
    } finally {
      isPromoCodeActivating.value = false;
    }
  };

  const deactivatePromoCode = async (code: string) => {
    try {
      isPromoCodeActivating.value = true;
      const data = await $fetch<PromoCodeData>(`/promo_codes/deactivate/`, {
        method: "POST",
        baseURL,
        credentials: "include",
        headers: userStore.getAuthorizationHeader(),
      });
      userStore.getUser();
      return data;
    } catch (error: any) {
      if (error.statusCode === 401) {
        const refresh = getRefresh() || "";
        await userStore.refreshTokens(refresh);
        await deactivatePromoCode(code);
      } else {
        throw error;
      }
    } finally {
      isPromoCodeActivating.value = false;
    }
  };

  return {
    isPromoCodePopupRequired,
    isPromoCodeChecking,
    currentPromoCodeData,
    activatePromoCode,
    deactivatePromoCode,
    checkPromoCode,
  };
});
