<template>
  <SharedPopupContainer @hide="hide">
    <SharedPopup>
      <SharedPopupHeader @hide="hide">
        {{ $t("promo_code.title") }}
      </SharedPopupHeader>
      <div :class="$style.content">
        <PromoCodeInput
          v-model="promoCode"
          :inactive="isPromoCodeActive"
          :current-promo-code-data="currentPromoCodeData"
          :error="promoCodeError"
        />
        <div v-if="currentPromoCodeData && currentGame">
          <SharedActionCard
            size="xs"
            :img="currentGame.logo || ''"
            :action="currentGame.title"
            :with-arrow="true"
            :discount="gameDiscountText"
            @click="handleGameClick"
          />
          <p :class="$style.hint">
            {{
              !isPromoCodeActive
                ? $t("promo_code.hint")
                : $t("promo_code.hint_activated")
            }}
          </p>
        </div>
        <SharedButtonPrimaryButton
          :class="$style.submit"
          :loading="loading"
          size="l"
          @click="submitPromoCode"
        >
          {{
            !isPromoCodeActive ? $t("promo_code.apply") : $t("promo_code.reset")
          }}
        </SharedButtonPrimaryButton>
      </div>
    </SharedPopup>
  </SharedPopupContainer>
</template>

<script setup lang="ts">
import { useNotify } from "~/composables/useNotify";
const promoCodesStore = usePromoCodesStore();
const { isPromoCodePopupRequired } = storeToRefs(promoCodesStore);
const promoCode = ref("");
const promoCodeError = ref<string>();
const loading = ref(false);
const gamesStore = useGamesStore();
const productStore = useProductsStore();
const userStore = useUserStore();
const notify = useNotify();
const route = useRoute();

const isPromoCodeActive = computed(() => {
  return Boolean(userStore.userData?.active_promo_code);
});
const currentPromoCodeData = computed(() => {
  return (
    userStore.userData?.active_promo_code ||
    promoCodesStore.currentPromoCodeData
  );
});
const currentGame = computed(() => {
  return currentPromoCodeData.value &&
    currentPromoCodeData.value.games?.length === 1
    ? currentPromoCodeData.value.games[0]
    : null;
});

const gameDiscountText = computed(() =>
  currentPromoCodeData.value
    ? `${toLocalNumber(Number.parseInt(currentPromoCodeData.value.percent))}%`
    : "",
);

const hide = () => {
  isPromoCodePopupRequired.value = false;
};
const init = () => {
  promoCodesStore.currentPromoCodeData = undefined;
};
const fetchDiscountData = () => {
  if (typeof route.name === "string" && route.name.includes("products-slug")) {
    const isCurrentGame = currentPromoCodeData.value?.games.some(
      (game) => route.params.slug === game.id.toString(),
    );
    if (isCurrentGame) {
      productStore.init(route.params.slug.toString());
    }
  } else {
    gamesStore.fetchGames(0);
  }
};
const submitPromoCode = async () => {
  try {
    promoCodeError.value = undefined;
    loading.value = true;
    if (isPromoCodeActive.value) {
      await promoCodesStore.deactivatePromoCode(promoCode.value);
      fetchDiscountData();
    } else if (currentPromoCodeData.value) {
      await promoCodesStore.activatePromoCode(promoCode.value);
      fetchDiscountData();
      hide();
    } else {
      await promoCodesStore.checkPromoCode(promoCode.value);
    }
  } catch (e: any) {
    if (e.data && e.data.detail) {
      notify({ text: e.data.detail, type: "error" });
    }
    if (e.data && !("detail" in e.data) && "code" in e.data) {
      promoCodeError.value = e.data.code[0] || e.data.code;
    }
  } finally {
    loading.value = false;
  }
};
onBeforeMount(() => {
  init();
});
const handleGameClick = async () => {
  const game = currentPromoCodeData.value?.games[0];
  if (!isPromoCodeActive.value) {
    try {
      await promoCodesStore.activatePromoCode(promoCode.value);
      if (game && "id" in game) {
        navigateTo(`/products/${game.id}`);
      }
      hide();
    } catch (e: any) {
      if (e.data && e.data.detail) {
        notify({ text: e.data.detail, type: "error" });
      }
      if (e.data && !("detail" in e.data) && "code" in e.data) {
        promoCodeError.value = e.data.code[0] || e.data.code;
      }
    }
  } else {
    if (game && "id" in game) {
      navigateTo(`/products/${game.id}`);
    }
    hide();
  }
};
</script>

<style module>
.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4xl);
}
.hint {
  margin-top: var(--spacing-lg);
}
.submit {
  width: 100%;
}
</style>
