<template>
  <div>
    <PageHeader />
    <slot />
    <PageFooter />
    <LastOrderButton />
    <Transition name="popup">
      <Auth v-if="userStore.authPopupIsRequired" />
    </Transition>
    <Transition name="popup">
      <PromoCode v-if="promoCodesStore.isPromoCodePopupRequired" />
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import Auth from "~/components/Auth.vue";
import PromoCode from "~/components/PromoCode.vue";
import { usePromoCodesStore } from "~/stores/promoCodes";
import LastOrderButton from "~/components/LastOrders/LastOrderButton.vue";

const userStore = useUserStore();
const promoCodesStore = usePromoCodesStore();
</script>
