<template>
  <SharedPopupContainer @hide="$emit('hide')">
    <SharedPopup
      :class="[$style.popup, $style['left-popup']]"
      :max-width="maxWidth"
    >
      <img src="~/assets/auth.png" />
    </SharedPopup>
    <SharedPopup
      :max-width="maxWidth"
      :class="$style.popup"
    >
      <SharedPopupHeader @hide="$emit('hide')" />
      <slot />
    </SharedPopup>
  </SharedPopupContainer>
</template>

<script lang="ts" setup>
const maxWidth = 425;
</script>

<style lang="scss" module>
.popup {
  height: 530px;
  @media screen and (max-width: $mobile) {
  }
}
.left-popup {
  padding: 0 !important;
  background: linear-gradient(292deg, #9cccc4 0%, #417867 101.01%) !important;
  overflow: hidden;
  @media screen and (max-width: $tablet) {
    display: none;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
