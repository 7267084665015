<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5839 11.5694C9.6381 12.305 8.44945 12.743 7.15849 12.743C4.07425 12.743 1.57397 10.2427 1.57397 7.15849C1.57397 4.07425 4.07425 1.57397 7.15849 1.57397C10.2427 1.57397 12.743 4.07425 12.743 7.15849C12.743 8.44945 12.305 9.6381 11.5694 10.5839L14.2213 13.2358C14.4934 13.5079 14.4934 13.9491 14.2213 14.2213C13.9491 14.4934 13.5079 14.4934 13.2358 14.2213L10.5839 11.5694ZM11.429 7.15849C11.429 9.51703 9.51703 11.429 7.15849 11.429C4.79995 11.429 2.88798 9.51703 2.88798 7.15849C2.88798 4.79995 4.79995 2.88798 7.15849 2.88798C9.51703 2.88798 11.429 4.79995 11.429 7.15849Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.15849 11.188C9.38395 11.188 11.188 9.38395 11.188 7.15849C11.188 4.93303 9.38395 3.12894 7.15849 3.12894C4.93303 3.12894 3.12894 4.93303 3.12894 7.15849C3.12894 9.38395 4.93303 11.188 7.15849 11.188ZM10.5612 11.8874C9.60388 12.5774 8.42836 12.984 7.15849 12.984C3.94117 12.984 1.33301 10.3758 1.33301 7.15849C1.33301 3.94117 3.94117 1.33301 7.15849 1.33301C10.3758 1.33301 12.984 3.94117 12.984 7.15849C12.984 8.42836 12.5774 9.60388 11.8874 10.5612L14.3917 13.0654C14.7579 13.4316 14.7579 14.0254 14.3917 14.3917C14.0254 14.7579 13.4316 14.7579 13.0654 14.3917L10.5612 11.8874ZM10.5839 11.5694C9.6381 12.305 8.44945 12.743 7.15849 12.743C4.07425 12.743 1.57397 10.2427 1.57397 7.15849C1.57397 4.07425 4.07425 1.57397 7.15849 1.57397C10.2427 1.57397 12.743 4.07425 12.743 7.15849C12.743 8.44945 12.305 9.6381 11.5694 10.5839L14.2213 13.2358C14.4934 13.5079 14.4934 13.9491 14.2213 14.2213C13.9491 14.4934 13.5079 14.4934 13.2358 14.2213L10.5839 11.5694ZM7.15849 11.429C9.51703 11.429 11.429 9.51703 11.429 7.15849C11.429 4.79995 9.51703 2.88798 7.15849 2.88798C4.79995 2.88798 2.88798 4.79995 2.88798 7.15849C2.88798 9.51703 4.79995 11.429 7.15849 11.429Z"
      fill="currentColor"
    />
  </svg>
</template>
