<template>
  <Popup
    :class="[
      $style['last-order-popup'],
      store.lastOrders.length <= 1 && $style['single-order'],
    ]"
  >
    <PopupHeader
      :show-back-btn="false"
      :class="$style['last-order-popup__header']"
      @hide="emit('hide')"
    >
      <Heading3>
        {{ t("topup.my_orders") }}
      </Heading3>
    </PopupHeader>
    <SingleOrderPopup
      v-if="isMobile && store.lastOrders.length <= 1"
      :order="store.lastOrders[0]"
    />
    <OrderHistoryList
      v-else
      :loading="false"
      :active-order-id="activeOrderId"
      :hide-short="store.lastOrders.length <= 1"
      :orders="store.lastOrders"
      @set-active-order-id="setActiveOrderId"
    />
  </Popup>
</template>

<script setup lang="ts">
import PopupHeader from "~/components/Shared/Popup/PopupHeader.vue";
import Popup from "~/components/Shared/Popup/Popup.vue";
import OrderHistoryList from "~/components/Profile/OrderHistory/OrderHistoryList.vue";
import { useUserStore } from "~/stores/user.ts";
import Heading3 from "~/components/Shared/Heading/Heading3.vue";
import SingleOrderPopup from "~/components/Profile/OrderHistory/SingleOrderPopup.vue";

const emit = defineEmits<{
  (e: "hide"): void;
}>();

const { t } = useI18n();
const { isMobile } = useDevice();
const store = useUserStore();
const activeOrderId = ref(store.lastOrders[0].id);

function setActiveOrderId(id: number) {
  activeOrderId.value = id;
}
</script>

<style module lang="scss">
.last-order-popup {
  background: var(--general-underlay-1-lvl) !important;
  max-width: none !important;
  width: 1100px !important;
  padding: var(--spacing-5xl) var(--spacing-5xl) var(--spacing-2lg) !important;

  &.single-order {
    width: 732px !important;
  }

  @media screen and (max-width: 600px) {
    padding: var(--spacing-5xl) var(--spacing-2lg) var(--spacing-2lg) !important;
    min-width: 100% !important;
  }

  @media screen and (max-width: 425px) {
    border-radius: var(--radius-6xl) !important;
    padding: var(--spacing-2xl) var(--spacing-xs) var(--spacing-6xl) !important;
  }

  &__header {
    padding: 0 var(--spacing-2xl);
    justify-content: flex-start;

    button {
      @media screen and (max-width: 600px) {
        right: 16px !important;
      }

      @media screen and (max-width: 425px) {
        right: 12px !important;
      }
    }

    @media screen and (max-width: 425px) {
      justify-content: center;
    }
  }
}
</style>
