<template>
  <svg
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.84737 2.74404H7.93989C6.27617 2.74404 5.40109 3.5867 5.40109 4.82909C5.40109 6.23353 6.00608 6.89156 7.24847 7.7352L8.27479 8.42662L5.32547 12.8334H3.12158L5.76841 8.89116C4.24612 7.80002 3.39167 6.74031 3.39167 4.94793C3.39167 2.70083 4.95816 1.16675 7.92908 1.16675H10.8784V12.8226H8.84737V2.74404Z"
      fill="white"
    />
  </svg>
</template>
