<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 28"
    fill="none"
    :class="[$style[props.size]]"
  >
    <path
      d="M4.90198 23.6923H18.9451C20.2374 23.6923 21.0989 22.8308 21.0989 21.5385V15.0769H11.7943V10.7692H25.622V21.3662C25.622 25.3292 22.9943 28 19.0312 28H0.378906V6.59077C0.378906 2.62769 3.0066 0 6.96968 0H23.6404V4.30769H7.05583C5.76352 4.30769 4.90198 5.16923 4.90198 6.46154V23.6923Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    size?: "l" | "s";
  }>(),
  {
    size: "s",
  },
);
</script>

<style lang="scss" module>
.l {
  width: 50.486px;
  height: 56px;
}
.s {
  width: 25.243px;
  height: 28px;
}
</style>
