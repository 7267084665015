<template>
  <div>
    <h4 :class="$style.caption">
      <slot name="caption" />
    </h4>
    <div :class="$style.links">
      <slot></slot>
    </div>
  </div>
</template>

<style module lang="scss">
.caption {
  margin-bottom: 24px;
  color: var(--general-transparent-light-50);
  font-size: 16px;
  font-weight: 300;
  line-height: 135%;
  letter-spacing: -0.032px;

  @media screen and (max-width: $tablet) {
    font-size: 14px;
    letter-spacing: -0.014px;
  }

  @media screen and (max-width: $mobile) {
    font-size: 12px;
  }
}
.links {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
</style>
