<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 14.9333C11.8292 14.9333 14.9333 11.8292 14.9333 8C14.9333 4.17083 11.8292 1.06667 8 1.06667C4.17083 1.06667 1.06667 4.17083 1.06667 8C1.06667 11.8292 4.17083 14.9333 8 14.9333ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="white"
      fill-opacity="0.35"
    />
    <path
      d="M8.64872 4.36719C10.1937 4.36719 11.2168 5.16524 11.2168 6.65902C11.2168 8.14258 10.1937 8.98155 8.58733 8.98155H7.22656V9.66706H8.18319C8.40074 9.66706 8.5771 9.84341 8.5771 10.061C8.5771 10.2785 8.40074 10.4549 8.18319 10.4549H7.22656V11.0329C7.22656 11.3635 6.95858 11.6315 6.62802 11.6315C6.29746 11.6315 6.02948 11.3635 6.02948 11.0329V10.4549H5.17516C4.95761 10.4549 4.78125 10.2785 4.78125 10.061C4.78125 9.84341 4.95761 9.66706 5.17516 9.66706H6.02948V8.98155H5.17516C4.95761 8.98155 4.78125 8.80519 4.78125 8.58764C4.78125 8.37009 4.95761 8.19373 5.17516 8.19373H6.02948V5.36719C6.02948 4.8149 6.4772 4.36719 7.02948 4.36719H8.64872ZM7.22656 7.92772H8.64872C9.43654 7.92772 10.0197 7.51846 10.0197 6.69995C10.0197 5.8712 9.43654 5.46195 8.64872 5.46195H7.22656V7.92772Z"
      fill="white"
    />
  </svg>
</template>
