<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M1.14844 5C1.14844 3.34315 2.47816 2 4.11845 2H14.0385C15.6788 2 17.0085 3.34315 17.0085 5V7.00999C17.0085 7.55676 16.5653 8 16.0185 8C15.4718 8 15.0285 7.55676 15.0285 7.00999V5C15.0285 4.44772 14.5853 4 14.0385 4H4.11845C3.94813 4 3.78785 4.04345 3.64789 4.11997L7.28246 5.71581C8.36696 6.19198 9.06848 7.27265 9.06848 8.46712V20H14.0385C14.5853 20 15.0285 19.5523 15.0285 19V16.99C15.0285 16.4432 15.4718 16 16.0185 16C16.5653 16 17.0085 16.4432 17.0085 16.99V19C17.0085 20.6569 15.6788 22 14.0385 22H4.11845C2.47816 22 1.14844 20.6569 1.14844 19V5Z"
      fill="currentColor"
    />
    <path
      d="M19.1548 14.4453C18.8515 14.9048 18.9745 15.5257 19.4294 15.8321C19.8843 16.1384 20.499 16.0142 20.8023 15.5547L22.603 12.8263C22.9338 12.3251 22.9338 11.6749 22.603 11.1737L20.8023 8.4453C20.499 7.98577 19.8843 7.8616 19.4294 8.16795C18.9745 8.4743 18.8515 9.09517 19.1548 9.5547L20.1087 11L14.0485 11C13.4962 11 13.0485 11.4477 13.0485 12C13.0485 12.5523 13.4962 13 14.0485 13L20.1087 13L19.1548 14.4453Z"
      fill="currentColor"
    />
  </svg>
</template>
