<template>
  <div :class="$style.container">
    <img
      :class="$style.picture"
      src="~/assets/footer/footer.png"
    />
  </div>
</template>

<style module lang="scss">
.container {
  position: relative;
  display: flex;
}
.picture {
  width: 100%;
  display: flex;
  margin: auto;
}
</style>
