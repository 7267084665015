<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.7328 5.69911C12.7483 4.75774 11.496 4.27842 10.1012 4.27842C7.62688 4.27842 5.53294 5.9495 4.7859 8.19496C4.5959 8.76497 4.48794 9.37383 4.48794 9.99997C4.48794 10.6261 4.59551 11.235 4.78551 11.805C5.53256 14.0504 7.62688 15.7216 10.1012 15.7216C11.3794 15.7216 12.4679 15.3848 13.3186 14.8148C14.3248 14.1412 14.9941 13.1351 15.2143 11.9475H10.1016V8.27276H19.0489C19.1612 8.89458 19.2216 9.54231 19.2216 10.216C19.2216 13.1091 18.1852 15.5446 16.3889 17.1985C14.817 18.6494 12.6662 19.5 10.1012 19.5C6.38756 19.5 3.17482 17.3711 1.61163 14.2663C0.968223 12.9838 0.601562 11.5329 0.601562 9.99997C0.601562 8.46701 0.968609 7.01609 1.61202 5.73359C3.17521 2.62881 6.38756 0.5 10.1012 0.5C12.6619 0.5 14.8124 1.44137 16.4576 2.97433L13.7328 5.69911Z"
      fill="white"
    />
  </svg>
</template>
