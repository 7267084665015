export type SearchResult = {
  id: number;
  logo: string | null;
  title: string;
  resourcetype: string;
  created: string;
};

export type PaginatedSearchResults = {
  count: number;
  next: string | null;
  previous: string | null;
  results: SearchResult[];
};

export const fetchResults = (url: string) =>
  $fetch<PaginatedSearchResults>(url);
