<template>
  <svg
    viewBox="0 0 19 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.80676 0.501879C4.83897 0.500946 2.84208 0.5 0.791588 0.5C0.538456 0.5 0.333252 0.705204 0.333252 0.958336V4.10223C1.38098 4.10223 2.23033 4.95158 2.23033 5.99931C2.23033 7.04704 1.38098 7.89639 0.333252 7.89639V11.0417C0.333252 11.2949 0.538456 11.5001 0.791588 11.5001C2.84248 11.5001 4.83661 11.4991 6.8013 11.4982L6.80455 11.4982H6.80652H6.80692C10.6518 11.4963 14.384 11.4946 18.2084 11.5001C18.4615 11.5001 18.6667 11.2949 18.6667 11.0417L18.6667 7.89711C17.619 7.89711 16.7696 7.04776 16.7696 6.00003C16.7696 4.9523 17.619 4.10295 18.6667 4.10295L18.6667 0.958336C18.6667 0.705204 18.4615 0.5 18.2084 0.5C14.3969 0.505476 10.6575 0.503704 6.80676 0.501879ZM8.66763 8.51846L11.2335 2.72879C11.2711 2.6442 11.2805 2.56901 11.2805 2.50322C11.2805 2.21185 11.0267 2.03327 10.773 2.03327C10.585 2.03327 10.4064 2.12726 10.3124 2.33404L7.74654 8.12371C7.70895 8.2083 7.69955 8.28349 7.69955 8.34928C7.69955 8.63125 7.95332 8.81922 8.21648 8.81922C8.39506 8.81922 8.57364 8.73463 8.66763 8.51846ZM5.13367 3.81905C5.13367 4.86232 5.91377 5.61423 7.00404 5.61423C8.0849 5.61423 8.865 4.86232 8.865 3.81905C8.865 2.77578 8.0849 2.01448 7.00404 2.01448C5.91377 2.01448 5.13367 2.77578 5.13367 3.81905ZM7.86873 3.80965C7.86873 4.30779 7.50217 4.66494 7.00404 4.66494C6.5153 4.66494 6.13934 4.31719 6.13934 3.80965C6.13934 3.31151 6.5153 2.95436 7.00404 2.95436C7.51157 2.95436 7.86873 3.31151 7.86873 3.80965ZM10.1338 7.08044C10.1338 8.12371 10.9139 8.87562 11.9948 8.87562C13.0757 8.87562 13.8652 8.12371 13.8652 7.08044C13.8652 6.03717 13.0851 5.27587 11.9948 5.27587C10.9139 5.27587 10.1338 6.02777 10.1338 7.08044ZM12.8689 7.07104C12.8689 7.56918 12.5023 7.92634 11.9948 7.92634C11.5061 7.92634 11.1395 7.56918 11.1395 7.07104C11.1395 6.5729 11.5061 6.21575 11.9948 6.21575C12.5117 6.21575 12.8689 6.5729 12.8689 7.07104Z"
      fill="white"
    />
  </svg>
</template>
