<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M4.04949 11.3819C3.47894 11.9524 3.04454 12.6336 2.76717 13.3751C2.38015 14.4096 3.30844 15.3327 4.41301 15.3327H11.5876C12.6922 15.3327 13.6205 14.4096 13.2334 13.3751C12.9561 12.6336 12.5217 11.9524 11.9511 11.3819C10.9033 10.334 9.48215 9.74538 8.00031 9.74538C6.51846 9.74538 5.09731 10.334 4.04949 11.3819Z"
      fill="currentColor"
    />
    <path
      d="M3.80983 4.85649C3.80983 7.17173 5.68507 9.04697 8.00031 9.04697C10.3155 9.04697 12.1908 7.17173 12.1908 4.85649C12.1908 2.54125 10.3155 0.666016 8.00031 0.666016C5.68507 0.666016 3.80983 2.54125 3.80983 4.85649Z"
      fill="currentColor"
    />
  </svg>
</template>
